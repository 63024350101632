<template>
  <div>
  
  
  <v-container fluid class="justify-center">
    
    <TitleComponent 
    class="primary--text text-uppercase text-decoration-underline text-left text-h5" 
    title="News" />
  
     <v-row 
      v-for="(item, i) in this.NewsData"   
        :key="i"
     >
        <v-col
        cols="12"
        align-self="start"
        >
      
       
        <v-list three-line >
          <v-list-item>
            <v-list-item-content  align="center">
              <v-list-item-title :class="$vuetify.breakpoint.mdAndUp ? 'text-h2 font-weight-bold' : 'text-h6 font-weight-bold'" v-html="item.Name"></v-list-item-title>
              <enlargeable-image 
              class=""
              :max-height="$vuetify.breakpoint.mdAndUp ? 600 : 200"
              :max-width="$vuetify.breakpoint.mdAndUp ? 800 : 200"
              :src="$api_url+item.Url"
              :lazy-src="$api_url+item.Url"
              :aspect-ratio="16/9"
              :alt="item.Name"
              :title="item.Name"
             
              />
              
              <v-list-item-subtitle :class="$vuetify.breakpoint.mdAndUp ? 'text-h5 font-weight-bold' : 'text-subtitle font-weight-bold'" v-html="item.Content"></v-list-item-subtitle>
              <v-list-item-title  class="text-overline-caption mb-4 d-flex " v-html="formatData(item.CreatedDate)"></v-list-item-title>
            </v-list-item-content>  
          </v-list-item>
        </v-list> 
        <v-divider></v-divider>
      </v-col>
    </v-row>  
  
  </v-container>
  </div>  
</template>

<script>

import EnlargeableImage from '@diracleo/vue-enlargeable-image';
import TitleComponent from '@/components/TitleComponent'
export default {
  name : 'News',
  components : {
    TitleComponent,
    EnlargeableImage 
  },
  data() {
    return {
    NewsData:[], 
    
    }
  },
  mounted(){
  
    this.getAllRecords();
   
  },  
  methods : {
    
    formatData(value){
      //alert(value);!isNaN(Date.parse(value)) 
      //alert(value)
      var findchar=value.toString().search('-');
      if ( !isNaN(parseFloat(value)) && (findchar < 0)) { 
          
          return Number(value).toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2});
      }
      else if( !isNaN(Date.parse(new Date(value))) ){
          
          return this.formatDate(value);
      }
      else{
          return value
      }
    },
    formatDate:function(value) {
        //alert(value);
        if ( (value !== '0000-00-00') && (value) ) {
          const date = new Date(value)
          return date.toLocaleDateString(['en-US'], {month: 'short', day: '2-digit', year: 'numeric'})  
          //if you want, you can change locale date string
        }
        else{
            if (value==null){
                value='0000-00-00';
            }
            return value
        }
      },  

    getAllRecords:function(){
      //alert(this.BranchCode);       
      this.$axios.get( this.$api_url+`/getAllRecords/news/where ID > 0/ ID desc`,{
          
        params: {
              
          }
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              //alert(response.data);
              this.NewsData= response.data;
              
            }
      })
      .catch(error => { console.log(error)})
    },  

  }
}
</script>

<style lang="scss" scoped>
  .agency-box {
    padding: 30px 20px;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
    max-height: 264px;
    overflow: hidden;
    height: auto;

    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }

      h1 {
        color: #fff;
        line-height: 1em;
        margin-bottom: 1em;
        max-width: 270px;
      }
  }


</style>